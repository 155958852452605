import React from "react"
import Seo from "../components/seo"
import NavbarEn from "../components/navbar_en"
import Footer from "../components/footer"
import Dishes from "../components/dishes"
import "../css/style.css"

const DishesPage = () => (
  <>
    <Seo title="Dishes" />
    <NavbarEn />
    <Dishes />
    <div className="footer-3">
      <Footer />
    </div>
  </>
)

export default DishesPage
