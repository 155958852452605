import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "../css/style.css"

export default function Dishes() {
  const data = useStaticQuery(graphql`
    query Dishes {
      gcms {
        menuIngleses {
          carta {
            url(transformation: { document: { output: { format: jpg } } })
          }
        }
        mariscosIngleses {
          carta {
            url(transformation: { document: { output: { format: jpg } } })
          }
        }
      }
    }
  `)

  let menu = data.gcms.menuIngleses
  let mariscos = data.gcms.mariscosIngleses

  return (
    <section>
      <div className="drink-area-alimentos">
        {menu.map(menu => (
          <div className="drink-relative">
            <div className="drink-area-id" id={menu.carta.url} />
            <img
              className="drink-area-alimentos-2"
              alt=""
              src={menu.carta.url}
            />
          </div>
        ))}
      </div>
      <div className="drink-area-alimentos">
        {mariscos.map(mariscos => (
          <div className="drink-relative">
            <div className="drink-area-id" id="mariscos" />
            <img
              className="drink-area-alimentos-2"
              alt=""
              src={mariscos.carta.url}
            />
          </div>
        ))}
      </div>
    </section>
  )
}
